
(function($) {
    function exitAnim(){
        $(window).load(function(){
        $('#body-wrap').css('opacity','1');
        $('#transition-loader').hide();
        $('.animated').animate({
            width: '0%'
        }, 500, function(){
            $('body').removeClass('travelled');
            localStorage.removeItem('travelling');
            //localStorage.clear();
        });
        });
    }

    if(localStorage.getItem('travelling') === 'true' || document.referrer === 'https://rodi.pt/'){
        $('body').addClass('travelled');
        exitAnim();
    } else {
        $('#body-wrap').css('opacity','1');
        $('#transition-loader').hide();
    }
})(jQuery);